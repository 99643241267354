import Hero from "../Sections/Hero";
import RecentProjects from "../Sections/RecentProjects";
import Services from "../Sections/Services";
import Banner from "../Sections/Banner";

const Home = () => {

    window.scrollTo(0, 0);
    
return(
    <article>
        <Hero/>
        <Services/>
        <RecentProjects/>
        <Banner/>
    </article>
)
}

export default Home;