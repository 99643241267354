import ModeBtn from "./ModeBtn";
import Navlink from "./Navlink";

const Navigation = () => {
    const toggleMenu =() => {
        const hamBtn = document.getElementById('hamBtn');
        const navMenu = document.getElementById('navMenu');
        navMenu.classList.toggle('max-md:right-0')
        navMenu.classList.toggle('max-md:-right-full')
        hamBtn.classList.toggle('open');
    }

   


    return(
        <div className="md:col-span-3  ">
            <div className="cursor-pointer flex items-center h-10 w-fit md:hidden " id="hamBtn"  onClick={toggleMenu}>
                <div id='hamburger' className="bg-darkblue before:bg-darkblue after:bg-darkblue dark:bg-gray1 dark:before:bg-gray1 dark:after:bg-gray1 transition-all before:transition-all after:transition-all duration-500 before:duration-500 after:duration-500 ease-in-out before:ease-in-out after:ease-in-out before:absolute after:absolute w-10 before:w-10 after:w-10 h-1 before:h-1 after:h-1 rounded-full after:rounded-full before:rounded-full before:-translate-y-4 after:translate-y-4  "> </div>
            </div>
            <div id="navMenu" className=" max-md:absolute max-md:bg-darkblue max-md:dark:bg-paleblue max-md:h-screen max-md:w-1/2 max-md:top-0  max-md:-right-full transition-all duration-500 -z-10 max-md:pt-20 max-md:pl-6  md:grid md:grid-cols-3 md:text-lg ">
                <nav  className="flex flex-col text-gray1 dark:md:text-gray1 md:text-darkblue font-medium md:flex-row md:col-span-2 md:justify-between md:items-center ">
                    <Navlink path={'/'} name={'Home'} />
                    <Navlink path={'/about'} name={'About'} />
                    <Navlink path={'/portfolio'} name={'Portfolio'} />
                    <Navlink path={'/contact'} name={'Contact'} />
                </nav>
                <ModeBtn />
            </div>
        </div>
    )
}

export default Navigation;