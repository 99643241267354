import { Link } from "react-router-dom";


const Hero = () => {
    return(
        <article className="grid w-full mb-14 mx-auto md:grid-cols-2 md:py-16 items-center lg:py-20 transition-all duration-500">
            <section className="md:order-last firstAnimation">
                <svg className="fill-darkblue dark:fill-gray1 w-36 md:w-52 lg:w-80 transition-all duration-500  max-md:mx-auto ml-auto" viewBox="0 0 363 588"  xmlns="http://www.w3.org/2000/svg">
                <path d="M0 130C0 58.203 58.203 0 130 0H261V260H130C58.203 260 0 201.797 0 130V130Z" />
                <path d="M261 458C261 529.797 202.797 588 131 588L-3.05176e-05 588L-1.01554e-05 328L131 328C202.797 328 261 386.203 261 458V458Z" />
                <rect x="294" y="520" width="69" height="68" rx="34"/>
                </svg>
            </section>
            <section className="text-darkblue dark:text-gray1 font-medium  mx-auto mt-5 md:ml-0 lg:text-2xl secondAnimation">
                <section className=" uppercase ">
                    <p >Hello</p>
                    <section className="flex mb-2">
                        <h1 className="font-bold text-5xl text-strawberryred md:text-6xl lg:text-8xl">Sheharyar</h1>
                        <p className="-rotate-90 z-0 md:ml-1 md:-mb-1 lg:ml-2">,here</p>
                    </section>
                    <p >Front-End Developer and UI Designer</p>
                </section>
                <section className="max-md:text-center mt-11 md:text-left md:text-xl">
                    <Link className="py-2 px-4 bg-darkblue text-gray1 dark:bg-gray1 dark:text-darkblue hover:bg-strawberryred dark:hover:bg-strawberryred mr-6" to={'/contact'}>Contact</Link>
                    <a className="underline hover:text-strawberryred" href="#services">Know More!</a>
                </section>
            </section>
        </article>
    )
}

export default Hero;