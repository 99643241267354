import { Link } from "react-router-dom";
import SocialLink from "../Components/SocialLinks";

const Footer = () => {
    return(
        <footer className="px-9 md:px-16 xl:px-32 w-full bg-darkblue dark:bg-paleblue text-gray1 py-4 footerAnimation mt-auto
            ">
                <div className="flex justify-between items-center w-full">
                    <div>
                        <h4 className="text-strawberryred font-bold tracking-wider text-xs mb-3">Site map</h4>
                        <nav className="flex justify-between flex-col md:flex-row text-sm font-medium">
                            <Link className="max-md:mt-1 md:mr-5" to={'/'}>Home</Link>
                            <Link className="max-md:mt-1 md:mr-5" to={'/about'}>About</Link>
                            <Link className="max-md:mt-1 md:mr-5" to={'/portfolio'}>Portfolio</Link>
                            <Link className="max-md:mt-1 md:mr-5" to={'/contact'}>Contact</Link>
                        </nav>
                    </div>
                    <div className="flex justify-center max-md:flex-col md:justify-between gap-4">
                        <div>
                        <svg className="fill-gray1 w-14"  viewBox="0 0 363 588" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 130C0 58.203 58.203 0 130 0H261V260H130C58.203 260 0 201.797 0 130V130Z" />
                            <path d="M261 458C261 529.797 202.797 588 131 588L-3.05176e-05 588L-1.01554e-05 328L131 328C202.797 328 261 386.203 261 458V458Z" />
                            <rect x="294" y="520" width="69" height="68" rx="34" />
                        </svg>
                        </div>
                        <div className="flex flex-row md:flex-col items-center justify-between gap-1">
                            <SocialLink link={'www.facebook.com'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0v24h24v-24h-24zm16 7h-1.923c-.616 0-1.077.252-1.077.889v1.111h3l-.239 3h-2.761v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"/></svg>
                            </SocialLink>
                            
                            <SocialLink link={'www.facebook.com'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z"/></svg>
                            </SocialLink>
                            <SocialLink link={'www.facebook.com'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.667 12c0 1.473-1.194 2.667-2.667 2.667-1.473 0-2.667-1.193-2.667-2.667 0-1.473 1.194-2.667 2.667-2.667 1.473 0 2.667 1.194 2.667 2.667zm3.846-3.232c.038.843.046 1.096.046 3.232s-.008 2.389-.046 3.233c-.1 2.15-1.109 3.181-3.279 3.279-.844.038-1.097.047-3.234.047-2.136 0-2.39-.008-3.232-.046-2.174-.099-3.181-1.132-3.279-3.279-.039-.845-.048-1.098-.048-3.234s.009-2.389.047-3.232c.099-2.152 1.109-3.181 3.279-3.279.844-.039 1.097-.047 3.233-.047s2.39.008 3.233.046c2.168.099 3.18 1.128 3.28 3.28zm-2.405 3.232c0-2.269-1.84-4.108-4.108-4.108-2.269 0-4.108 1.839-4.108 4.108 0 2.269 1.84 4.108 4.108 4.108 2.269 0 4.108-1.839 4.108-4.108zm1.122-4.27c0-.53-.43-.96-.96-.96s-.96.43-.96.96.43.96.96.96c.531 0 .96-.43.96-.96zm6.77-7.73v24h-24v-24h24zm-4 12c0-2.172-.009-2.445-.048-3.298-.131-2.902-1.745-4.52-4.653-4.653-.854-.04-1.126-.049-3.299-.049s-2.444.009-3.298.048c-2.906.133-4.52 1.745-4.654 4.653-.039.854-.048 1.127-.048 3.299 0 2.173.009 2.445.048 3.298.134 2.906 1.746 4.521 4.654 4.654.854.039 1.125.048 3.298.048s2.445-.009 3.299-.048c2.902-.133 4.522-1.745 4.653-4.654.039-.853.048-1.125.048-3.298z"/></svg>
                            </SocialLink>
                            
                        </div>
                    </div>
                </div>
                <p className="font-mono mt-10 text-xs font-thin">Copyright Resvered to <span>Sheharyar</span></p>
            </footer>
    )
}

export default Footer;