import EmailForm from "../Sections/EmailForm";
import ExternalLink from "../Sections/ExternalLink";

const Contact = () => {
    window.scrollTo(0, 0);
    return(
        <article>
            <h2 className="font-medium text-5xl text-strawberryred firstAnimation md:text-6xl lg:text-8xl firstAnimation">Contact</h2>
            <h3 className="text-darkblue dark:text-gray1 font-medium text-4xl md:text-5xl lg:text-6xl secondAnimation">Let's Talk</h3>
            <EmailForm/>
            <ExternalLink/>
        </article>
    )
}

export default Contact;