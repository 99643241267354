import guru from '../asset/icon/Workspace links/Guru.png'
import fiverr from '../asset/icon/Workspace links/Fiverr-Logo.png'
import peolpleperhour from '../asset/icon/Workspace links/Peopleperhour2.png'



const ExternalLink = () => {
    return(
        <article className="mx-auto mt-5 mb-8 fourthAnimation">
            {/* <article>
                <h3 className="text-darkblue dark:text-gray1 font-medium text-3xl md:text-4xl lg:text-5xl">Social Links</h3>
                <section>
                    
                </section>
            </article> */}
            <article>
                <h3 className="text-darkblue dark:text-gray1 font-medium text-3xl md:text-4xl lg:text-5xl">Work Links</h3>
                <section className='flex items-center gap-5'>
                    <a href='https://www.peopleperhour.com/freelancer/technology-programming/sheharyar-javaid-ui-designer-and-front-end-jwyyxqa#reviews' target={'_blank'} title="Guru" className='bg-gray1 w-28 hover:scale-125 transition-all duration-300'>
                        <img className='w-full' src={guru} alt="Guru" />
                    </a>
                    <a href='https://www.fiverr.com/sheri0441' target={'_blank'} title="Fiverr" className='w-28 hover:scale-125 transition-all duration-300'>
                        <img className='w-full' src={fiverr} alt="Guru" />
                    </a>
                    <a href='https://www.peopleperhour.com/site/register?rfrd=7622350.5' target={'_blank'} title="Peopleperhour" className=' w-16 hover:scale-125 transition-all duration-300'>
                        <img className='w-full' src={peolpleperhour} alt="PeoplePerHour" />
                    </a>
                </section>
            </article>
        </article>
    )
}

export default ExternalLink;