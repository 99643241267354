import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Input from '../Components/Input';


const EmailForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_5tskdjb', 'template_1f729op', form.current, 'sqtE4HOzTRQyWKXKA')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });

        document.getElementById('form').reset();

    };
    return(
        <form ref={form} id='form' onSubmit={sendEmail} className="mx-auto text-center bg-paleblue py-5 px-8 w-full md:w-96 mt-5 thirdAnimation">
            <Input id={'name'} type={'text'}/>
            <Input id={'subject'} type={'text'}/>
            <Input id={'email'} type={'email'}/>
            <Input id={'message'} type={'textarea'} />
            <button type='submit' className='ml-auto bg-gray1 dark:bg-gray1 py-2 px-3 hover:bg-strawberryred dark:hover:bg-strawberryred font-medium'>Send</button>
        </form>
    )
}

export default EmailForm;