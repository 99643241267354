import { Link } from "react-router-dom";


const Logo = () => {
return(
    <Link to={'/'}>
        <svg className='w-8 dark:fill-gray1 fill-darkblue transition-all duration-500 ease-in-out' 
        
        viewBox="0 0 363 588" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 130C0 58.203 58.203 0 130 0H261V260H130C58.203 260 0 201.797 0 130V130Z" />
            <path d="M261 458C261 529.797 202.797 588 131 588L-3.05176e-05 588L-1.01554e-05 328L131 328C202.797 328 261 386.203 261 458V458Z" />
            <rect x="294" y="520" width="69" height="68" rx="34" />
        </svg>
    </Link>
)
}

export default Logo;