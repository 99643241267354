import { Children } from "react";
import { Link } from "react-router-dom";


const SocialLink = ({link, children}) => {
    return(
        <a target='_blank' className="fill-gray1 transition-colors  duration-500 hover:fill-strawberryred" href={link}>{children}</a>
    )
}

export default SocialLink;