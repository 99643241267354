import Skill from "../Sections/Skill";
import pic from '../asset/Sheharyar.png'

const About = () => {

    
    window.scrollTo(0, 0);
    return(
        <article>
            <h2 className="font-medium text-5xl text-strawberryred firstAnimation md:text-6xl lg:text-8xl">About</h2>
            <article className="dark:text-gray1 text-darkblue my-7">
                <h3 className="text-4xl md:text-5xl lg:text-6xl secondAnimation mb-5">Who I Am</h3>
                <section className="thirdAnimation md:grid md:grid-cols-2 items-center">
                    <div className="w-72 h-72 mx-auto md:w-80 lg:w-96 md:h-80 lg:h-96 bg-darkblue dark:bg-gray1 md:order-last rounded-full max-md:my-8 overflow-hidden relative">
                        <img className="absolute -top-1/4" src={pic} alt="Sheharyar" />
                    </div>
                    <p className="text-lg lg:text-xl">Hello there, I am Sheharyar.I am a front-end developer and UI designer with a passion for creating beautiful and intuitive digital experiences. With a couple of years of experience under my belt, I have developed a strong foundation in HTML, CSS, and JavaScript, and have a keen eye for design and usability. Whether I am working on a new website or improving the UI of an existing application, I am always striving to create the best possible user experience.</p>
                </section>
                <p className="text-lg lg:text-xl mt-5 md:mt-8 lg:mt-10 fourthAnimation" >In addition to my technical skills, I am a strong problem-solver and I thrive in collaborative environments. I am always looking for ways to improve the user experience and make the web a better place for everyone. I am excited to continue my career as a front-end developer and UI designer and to take on new challenges as I grow in my profession.</p>
            </article>
            <Skill/>
        </article>
    )
}

export default About;