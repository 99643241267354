import { useEffect } from "react";
import Logo from "../Components/Logo";
import Navigation from "../Components/Navigation";

const Header = () => {
    useEffect(() => {
        const nav = document.getElementById('header')
        function handleScroll() {
          if (window.scrollY > 0) {
            nav.classList.add('bg-gray1')
            nav.classList.add('dark:bg-darkblue')
            nav.classList.add('nav')
            nav.classList.add('border-b')
            nav.classList.add('border-strawberryred')
        } else {
            nav.classList.remove('bg-gray1')
            nav.classList.remove('dark:bg-darkblue')
            nav.classList.remove('nav')
            nav.classList.remove('border-b')
            nav.classList.remove('border-strawberryred')
          }
        }
    
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    return(
        <header id="header" className="fixed top-0 px-9 md:px-16 xl:px-32 flex justify-between w-full items-center max-w-screen-2xl py-1 md:grid md:grid-cols-4 md:py-2 z-50 headerAnimation">
                <Logo/>
                <Navigation/>
            </header>
    )
}

export default Header;