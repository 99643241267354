

const ServicesCard = ({first,second, despcription, extraStyle}) => {
    return(
        <div className={`bg-darkblue dark:bg-paleblue p-4 text-center overflow-hidden relative shadow-black drop-shadow-lg mx-auto transition-colors duration-500 max-w-xs fourthAnimation ${extraStyle}`}>
            <div className="w-16 h-16 bg-gray1 rounded-full blur-2xl -translate-x-5 -translate-y-5 absolute top-0 left-0 -z-10"></div>
            <div className="w-12 h-12 bg-gray1 rounded-full blur-xl translate-x-5 translate-y-5 absolute bottom-0 right-0  -z-10"
            ></div>
            <h3 className="text-4xl text-left text-strawberryred  font-medium capitalize mb-5">{first} <br/> {second}</h3>
            <p className="text-gray1 text-left">
                {despcription}
            </p>
        </div>
    )
}

export default ServicesCard;