import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import IP from '../asset/Projects/IP Ttracker.png'
import Loop from '../asset/Projects/LoopStuidio.png'
import Sneaker from '../asset/Projects/Sneaker.png'
import Sheharyar from '../asset/Projects/Shehrayar.png'
import countriesData from '../asset/Projects/countriesData.png'
import ProjectCard from './ProjectCard'



const HorizontalScroll = () => {
    
  return (
    <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} wrapperClassName="relative customstyle h-96 mt-2 mb-28" scrollContainerClassName='customstyle '>
      <ProjectCard image={Sheharyar} name={"Sheharyar"} role={"UI & Front-end"} Class={'thirdAnimation min-w-max'} link={'https://portfolio-96p.pages.dev/'} />
      <ProjectCard image={countriesData} name={"Countries Data"} role={"Front-end"} Class={'thirdAnimation min-w-max'} link={"https://countries-data.pages.dev/"} />
      <ProjectCard image={IP} name={"IP Tracker"} role={"Front-end"} Class={'thirdAnimation min-w-max'} link={"https://ip-tracker.pages.dev/"} />
      <ProjectCard image={Loop} name={"Loop Studio"} role={"Front-end"} Class={'thirdAnimation min-w-max'} link={"https://loopstudio-bkf.pages.dev/"} />
      <ProjectCard image={Sneaker} name={"Sneaker E-commece"} role={"Front-end"} Class={'thirdAnimation min-w-max'} link={"https://sneaker-ecommrace.pages.dev/"} />
    </ScrollMenu>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <div className='absolute right-20 -bottom-10 cursor-pointer bg-darkblue dark:bg-gray1 p-3 rounded-full hover:bg-strawberryred   dark:hover:bg-strawberryred fourthAnimation' disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
      <svg className='fill-gray1 dark:fill-darkblue w-10 h-10' clipRule="evenodd"  strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m13.789 7.155c.141-.108.3-.157.456-.157.389 0 .755.306.755.749v8.501c0 .445-.367.75-.755.75-.157 0-.316-.05-.457-.159-1.554-1.203-4.199-3.252-5.498-4.258-.184-.142-.29-.36-.29-.592 0-.23.107-.449.291-.591 1.299-1.002 3.945-3.044 5.498-4.243z"/></svg>
    </div>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <div className='absolute -bottom-10 right-0 cursor-pointer bg-darkblue dark:bg-gray1 text-gray1 dark:text-darkblue  p-3 rounded-full hover:bg-strawberryred dark:hover:bg-strawberryred  fourthAnimation' disabled={isLastItemVisible} onClick={() => scrollNext()}>
      <svg className='fill-gray1 dark:fill-darkblue w-10 h-10 rotate-180 ' clipRule="evenodd"  strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m13.789 7.155c.141-.108.3-.157.456-.157.389 0 .755.306.755.749v8.501c0 .445-.367.75-.755.75-.157 0-.316-.05-.457-.159-1.554-1.203-4.199-3.252-5.498-4.258-.184-.142-.29-.36-.29-.592 0-.23.107-.449.291-.591 1.299-1.002 3.945-3.044 5.498-4.243z"/></svg>
    </div>
  );
}



export default HorizontalScroll;