import { Outlet } from "react-router-dom";
import Footer from "../Sections/Footer";
import Header from "../Sections/Header";

const Layout = () => {

    window.scrollTo(0, 0);
    return(
        <div className="max-w-screen-2xl font-Exo2 mx-auto bg-gray1 dark:bg-darkblue min-h-screen">
            <Header/>
            <main  className="px-9 md:px-16 xl:px-32 pt-20 relative overflow-hidden">
                <div className="absolute w-40 h-40 bg-darkblue dark:bg-gray1 top-0 right-0 rounded-full blur-3xl translate-x-1/2 -translate-y-1/2 transition-all duration-500"></div>
                <div className="absolute w-32 h-32 bg-darkblue dark:bg-gray1 top-1/4 right-full rounded-full blur-3xl translate-x-1/2 -translate-y-1/2 transition-all duration-500"></div>
                <div className="absolute w-48 h-48 bg-darkblue dark:bg-gray1 top-1/2 right-0 rounded-full blur-3xl translate-x-1/2 -translate-y-1/2 transition-all duration-500"></div>
                <div className="absolute w-32 h-32 bg-darkblue dark:bg-gray1 top-2/3 right-full rounded-full blur-3xl translate-x-1/2 -translate-y-1/2 transition-all duration-500"></div>
                <div className="absolute w-32 h-32 bg-darkblue dark:bg-gray1 top-3/4 right-0 rounded-full blur-3xl translate-x-1/2 -translate-y-1/2 transition-all duration-500"></div>
                <div className="absolute w-32 h-32 bg-darkblue dark:bg-gray1 top-[90%] right-full rounded-full blur-3xl translate-x-1/2 -translate-y-1/2 transition-all duration-500"></div>


                <Outlet />
            </main>
            <Footer/>
        </div>
    )
}

export default Layout;