import HorizontalScroll from '../Components/HorizontalScroll'

const Portfolio = () => {
    window.scrollTo(0, 0);
    return(
        <article>
            <header >
                <h2 className="font-medium text-5xl text-strawberryred firstAnimation md:text-6xl lg:text-8xl scroll-">Portfolio</h2>
                <p className="dark:text-gray1 text-darkblue secondAnimation mb-5 capitalize h-">Showcasing my skills and experience</p>
            </header>
            <HorizontalScroll/>
        </article>
    )
}

export default Portfolio;