

const ModeBtn = () => {
  const darkMode = () => {
    const html = document.getElementById("html");
    html.classList.toggle("dark");
    const modeCircle = document.getElementById('modeCircle');
    modeCircle.classList.toggle('mt-auto')
  } 

    
  

return(
    <div className="ml-1.5 md:ml-auto max-md:mt-4">
        <input className="hidden" type="checkbox" id="mode" name="mode" />
        <label htmlFor="mode" className="cursor-pointer" onClick={darkMode} >
            <div className="w-6 h-9 dark:border-gray1 border-darkblue border-2 rounded-full p-0.5 -rotate-90 md:rotate-0 bg-strawberryred flex">
                <div id="modeCircle" className="w-4 h-4 bg-darkblue dark:bg-gray1 rounded-full transition-all duration-500"></div>
            </div>
        </label>
    </div>
)
}

export default ModeBtn;