import Figma from '../asset/icon/Skills/Design/Figma.png'
import Photoshop from '../asset/icon/Skills/Design/Photoshop.png'
import Illustrator from '../asset/icon/Skills/Design/Illustrator.png'
import Bootstrap from '../asset/icon/Skills/Develop/Bootstrap.svg'
import CSS3 from '../asset/icon/Skills/Develop/CSS 3.png'
import SCSS from '../asset/icon/Skills/Develop/SCSS.png'
import JavaScript from '../asset/icon/Skills/Develop/JavaScript.png'
import react from '../asset/icon/Skills/Develop/React js.svg'
import html from '../asset/icon/Skills/Develop/HTML 5.png'
import tailwind from '../asset/icon/Skills/Develop/Tailwind CSS.svg'




const Skill = () => {
    return(
        <article className='mb-5'>
            <h3 className="text-4xl md:text-5xl lg:text-6xl fifthAnimation mb-3 text-darkblue dark:text-gray1">Skills</h3>
            <section className='flex sixthAnimation gap-5 mb-2 flex-wrap'>
                <img className='w-14 hover:scale-150 transition-all duration-300' src={Photoshop} alt="Photoshop" title='Photoshop' />
                <img className='w-14 hover:scale-150 transition-all duration-300' src={Illustrator} alt="Illustrator" title='Illustrator' />
                <img className='w-14 hover:scale-150 transition-all duration-300' src={Figma} alt="Figma" title='Figma' />

            </section>
            <section className='flex sixthAnimation gap-5 flex-wrap'>
                <img className='w-14 hover:scale-150 transition-all duration-300' src={html} alt="HTML" title='HTML'/>
                <img className='w-14 hover:scale-150 transition-all duration-300' src={CSS3} alt="CSS" title='CSS'/>
                <img className='w-14 hover:scale-150 transition-all duration-300' src={JavaScript} alt="JavaScript" title='JavaScript'/>
                <img className='w-14 hover:scale-150 transition-all duration-300' src={tailwind} alt="Tailwind" title='Tailwind CSS'/>
                <img className='w-14 hover:scale-150 transition-all duration-300' src={Bootstrap} alt="Bootstrap" title='Bootstrap'/>
                <img className='w-14 hover:scale-150 transition-all duration-300' src={react} alt="React Js" title='React js'/>
                <img className='w-14 hover:scale-150 transition-all duration-300' src={SCSS} alt="SCSS" title='SCSS'/>
            </section>
        </article>
    )
}

export default Skill;