

const Input = ({id, type}) => {
    return(
        <div className='input'>
            {/* <label htmlFor={id}>{id}</label> */}
            {(type === 'textarea')? 
            <textarea name={id} id={id} rows='5' required placeholder={id} className="placeholder:capitalize my-1 border border-gray1 bg-paleblue placeholder:text-gray1 placeholder:text-xl md:placeholder:text-2xl text-gray1 md:placeholder:font-normal w-full" ></textarea> 
            : <input required name={id} id={id} type={type} placeholder={id} className="placeholder:capitalize mb-5 border-b border-gray1 placeholder:text-gray1 bg-paleblue text-gray1 placeholder:text-xl md:placeholder:text-2xl md:placeholder:font-normal w-full" />}
        </div>
    )
}

export default Input;