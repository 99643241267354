import { Link } from 'react-router-dom';
import ProjectCard from '../Components/ProjectCard';
import IP from '../asset/Projects/IP Ttracker.png'
import countriesData from '../asset/Projects/countriesData.png'
import Sneaker from '../asset/Projects/Sneaker.png'


const RecentProjects = () => {
    return(
        <article className='grid grid-cols-1 mb-9'>
            <section className="pt-20 mb-5 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 lg:grid-rows-2 gap-5 scroll-pt-3" >
                <section className="md:col-span-2 fifthAnimation">
                    <h2 className="text-strawberryred font-bold text-4xl max-md:text-center md:text-5xl lg:text-6xl" id="services">Newest <br/>Projects</h2>
                </section>
                <ProjectCard image={IP} name={"IP Tracker"} role={"Front-end"} link={"https://ip-tracker.pages.dev/"} />
                <ProjectCard image={countriesData} name={"Countries Data"} role={"Front-end"} link={"https://countries-data.pages.dev/"} />
                <ProjectCard image={Sneaker} name={"Sneaker E-commece"} role={"Front-end"} link={"https://sneaker-ecommrace.pages.dev/"} />
            </section>
            <Link to={'/portfolio'} className='mx-auto font-medium text-darkblue dark:text-strawberryred py-1 px-2 border border-darkblue dark:border-strawberryred hover:bg-darkblue hover:text-gray1 dark:hover:bg-strawberryred dark:hover:text-darkblue transition-colors duration-500'>View More</Link>
        </article>
    )
}

export default RecentProjects;