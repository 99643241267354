

const ProjectCard = ({itemId, Class, image, role, name, link}) => {
    return(
        <article className={`lg:col-span-2 hover:bg-darkblue dark:hover:bg-gray1 block hover:bg-opacity-10 dark:hover:bg-opacity-10 p-3 ${Class} mx-auto`} id={itemId} >
            <section className="bg-darkblue dark:bg-gray1 w-full  py-3 px-2">
                <img className='mx-auto shadow-sm shadow-strawberryred' src={image} alt={name}/>
            </section>
            <section className='text-darkblue dark:text-gray1 max-md:text-center'>
                <p className='font-medium text-xl'>{name}</p>
                <div className='overflow-hidden'>
                    <p className='uppercase'>{role}</p>
                    <a className='capitalize text-strawberryred' href={link} target={'_blank'} >View Project</a>
                </div>
            </section>
        </article>
    )
}

export default ProjectCard