import {BrowserRouter,Routes, Route}from 'react-router-dom'
import Home from './Pages/Home';
import Layout from './Pages/Layout';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Portfolio from './Pages/Portfolio';

function App() {

  return (
    <div className='bg-gray1 dark:bg-darkblue min-h-screen transition-all duration-500 ease-in-out scroll-smooth'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout/>}>
            <Route index element={<Home/>}/>
            <Route path='/about' element={<About/>} />
            <Route path='/portfolio' element={<Portfolio/>} />
            <Route path='/contact' element={<Contact/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
