import { Link } from "react-router-dom";


const Banner = () => {
    return(
        <article className="bg-darkblue dark:bg-paleblue p-9 grid mb-5 font-medium md:grid-cols-2">
            <p className="text-gray1  text-2xl max-md:text-center max-md:mb-5 capitalize">Work together towards a common goal</p>
            <Link to={'/contact'} className='bg-gray1 py-2 px-3 mx-auto hover:bg-paleblue dark:hover:bg-darkblue hover:text-gray1 blue transition-colors duration-500 md:py-3 md:px-5'>Contact</Link>
        </article>
    )

}

export default Banner;