import { NavLink } from "react-router-dom";

const Navlink = ({path, name}) => {
    return(
        <NavLink className={({ isActive }) => (isActive ? 'text-strawberryred max-md:mt-4 relative after:w-full after:h-0.5 after:rounded-full after:bg-strawberryred transition-all after:absolute after:-bottom-1 after:right-0 duration-500' : 'max-md:mt-4 relative md:hover:after:w-1 md:hover:after:h-1 after:rounded-full after:absolute after:-bottom-1 after:right-2/4 after:bg-darkblue after:dark:bg-gray1 transition-all')}  to={path}>
            {name}
        </NavLink>
    )
}

export default Navlink;