import ServicesCard from "../Components/ServicesCard";


const Services = () => {
    return(
        <section className="py-20 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 " id="services">
            <section className="md:col-span-2 thirdAnimation">
                <h2 className="text-strawberryred font-bold text-4xl max-md:text-center md:text-5xl lg:text-6xl" >Expert <br/>Services</h2>
                <p className="text-darkblue dark:text-gray1 text-lg max-md:text-center">You Can Count On</p>
            </section>
            <ServicesCard first={'Fix'} second={'Bug'}  despcription={"I will locate and resolve any issues within your HTML, CSS, and JavaScript code."}  />
            <ServicesCard first={'Edit &'} second={'Customize'}  despcription={"I am able to edit and customize HTML, CSS, Js & React to meet your specific needs."}  />
            <ServicesCard first={'Portfolio'} second={'Web'}  despcription={"I will craft the visual and interactive elements of your portfolio website using React technology. "}  />
            <ServicesCard first={'React'} second={'Web'}  despcription={"I offer front-end development services using ReactJS, and I am able to build a professional website that meets your requirements and goals."}  />
            <ServicesCard first={'HTML, CSS'} second={'JavaScript'}  despcription={"I offer front-end development services using HTML, CSS and JavaScript  to create a professional and dynamic website for you."}  />
        </section>
    )
}

export default Services;